import React, { ReactElement, useCallback, useState } from "react";
import {
  faHouse,
  faTruck,
  faChevronRight,
  faChevronLeft,
  faInfoCircle,
  faCheck,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useAsync } from "react-async";
import toast, { Toaster } from "react-hot-toast";
import { useSelector, useDispatch, useStore } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { GetReferrerFromCode } from "src/api/referrals";
import { CheckoutBanner } from "src/screens/tasting_menu/components";
import { RootState } from "src/store";
import { addGoodsToCart } from "src/store/actions";
import { updateOrderOnMeal } from "src/store/actions";
import styled, { keyframes } from "styled-components";
import {
  CenteredByColumn,
  Text12300,
  CenteredByColumnLeft,
  BorderGrey,
  Centered,
  Yellow,
  Beige,
  Grey,
  Pink,
  text12300,
  card,
  centeredByColumn,
  NavHeight,
  textTitle,
  centered,
  ActionBlack,
  actionBlack,
  actionGrey,
  TextTitle,
  WinterBlue,
  SummerBlue,
  FallOrange,
} from "./../Shared";
import { breakPoint } from "./MyReferralCodeEventMember";
import { LOGGING, PopUp, IconImages, SupportedSanJoseZipCodes } from "..";
import { DishChooserContainer } from "../dish/DishChooserContainer";

export interface ReferralAwardProps {
  promo: any;
  socialInviteCode: string;
  dishes: any[];
  promoMeals: any[];
  restaurant: string;
}
const topHeight = 90;
const ContentContainer = styled(CenteredByColumn)`
  width: 100vw;
  /* height: 100vh; */
  /* padding: ${NavHeight}px 0 20px 20px; */
  justify-content: flex-start;
  background-color: ${Beige};
  overflow-y: scroll;
  padding-top: ${topHeight}px;
  padding-bottom: 200px;
`;
const Title = styled(Centered)`
  /* margin: 0px 0px 10px 0px; */
  padding-left: 30px;
  text-transform: none;
  text-align: center;
  /* justify-content: flex-start; */
  /* align-items: flex-start; */
  /* margin-bottom: 20px; */
  width: 100%;
  /* position: fixed;
    top: 0px;
    left: 0px; */
  /* height: ${NavHeight}px;   */
  height: 90px;
  position: fixed;
  top: 0px;
  left: 0px;
  ${card}
  border-bottom: 1px solid ${BorderGrey};
  z-index: 1;
  @media only screen and (min-width: 500px) {
    justify-content: center;
  }
`;
const Icon = styled.h1`
  font-size: 50px;
  line-height: 1;
  margin: 0 0px 5px 10px;
  /* position: absolute;
    right: calc(50% + 85px); */
  /* margin:0 0 10px 0; */
`;
const TitleText = styled(CenteredByColumnLeft)`
  /* width: 170px; */
  span {
    ${text12300}
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 5px;
  }
  label {
    ${textTitle}
    font-size: 13px;
    text-transform: none;
  }
  b {
    ${text12300}
    font-size: 8px;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 3px;
    text-transform: capitalize;
    color: grey;
  }
`;

const Section = styled(CenteredByColumnLeft)`
  width: 100%;
  margin-top: 35px;
  padding-left: 30px;
  @media only screen and (min-width: 800px) {
    margin-left: 15%;
    margin-top: 45px;
  }
`;
const SectionSubTitle = styled(Centered)`
  ${text12300}
  font-size: 10px;
  /* font-size: 14px; */
  font-weight: 400;
  line-height: 2;
  /* margin-left: 30px; */
  margin-bottom: 15px;
  text-transform: uppercase;
  border-bottom: 1px solid black;
  /* ${card} */
  /* padding: 5px 10px; */
  /* border-radius: 5px; */
  svg {
    margin-right: 5px;
    display: none;
  }
  label {
    color: grey;
    margin-right: 5px;
  }
  b {
    font-weight: 500;
    margin-left: 5px;
    font-size: 13px;
  }
`;
const SectionContent = styled(CenteredByColumnLeft)`
  width: 100%;
  /* ${card} */
`;
const IMAGE_WIDTH = 140;
const IMAGE_HEIGHT = 90;

const CarouselMobile = styled(Centered)`
  width: 100%;
  overflow-x: scroll;
  justify-content: flex-start;
  align-items: flex-start;
  /* padding: 0 0 0 20px; */
  margin-bottom: -20px;
  margin-top: 10px;
  @media only screen and (min-width: ${breakPoint}px) {
    display: none;
  }
`;
const CarouselDesktop = styled(Centered)`
  display: none;
  width: 100%;
  overflow-x: scroll;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 50px;
  margin-top: 10px;
  @media only screen and (min-width: ${breakPoint}px) {
    display: flex;
  }
`;
const Dish = styled.button`
  ${centeredByColumn}
  margin: 0px 20px 25px 0;
  padding: 0px 0px 40px 0px;
  ${card}
  border: 1px solid ${BorderGrey};
  align-self: stretch;
  justify-content: flex-start;
  position: relative;
  width: ${IMAGE_WIDTH}px;
`;
const DishOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: ${IMAGE_HEIGHT}px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
`;

const DishIndex = styled(CenteredByColumn)`
  position: absolute;
  left: 0px;
  top: 0px;
  text-transform: none;
  color: white;
  z-index: 1;
  width: 100%;
  height: ${IMAGE_HEIGHT}px;
  text-align: right;
  line-height: 1.5;
  font-size: 10px;
  padding: 5px;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: flex-end;
  span {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 0px 7px;
    border-radius: 5px;
  }
`;
const SeeMore = styled(Dish)`
  ${centeredByColumn}
  padding: 0px;
  label {
    ${textTitle}
    font-size: 20px;
    margin-bottom: 20px;
  }
  span {
    ${text12300}
    text-transform: uppercase;
    font-weight: 500;
    border-bottom: 1px solid black;
  }
`;
type ImageProps = { backgroundImageUrl: string };
const Image = styled.div<ImageProps>`
  background-image: url(${(props) => props.backgroundImageUrl});
  background-size: cover;
  width: ${IMAGE_WIDTH}px;
  height: ${IMAGE_HEIGHT}px;
  background-position: center;
`;

const Name = styled(Centered)`
  ${text12300}
  font-size: 11px;
  padding: 10px 15px;
  line-height: 1.5;
  text-align: left;
`;
const Selected = styled(Centered)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: green;
  color: white;
  position: absolute;
  right: -10px;
  top: -10px;
`;
const SectionTitle = styled.span`
  ${textTitle}
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 22px;
  /* text-transform: uppercase; */
  line-height: 1;
`;
const Footer = styled(Centered)`
  width: 100%;
  height: ${NavHeight}px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  ${card}
  border-top: 1px solid ${BorderGrey};
`;
const NextButton = styled(ActionBlack)``;
const RadioGroupContainer = styled(CenteredByColumnLeft)`
  ${text12300}
  margin-top: 12px;
  label {
    margin-bottom: 15px;
    ${centered}
    input[type="radio"] {
      margin: 0 10px 0 0;
      height: 18px;
    }
    line-height: 20px;
  }
`;
const PriceContainer = styled(CenteredByColumnLeft)`
  ${text12300}
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  justify-content: flex-start;
  padding: 0px 0px 10px 15px;
  line-height: 1;
`;
const PriceRegular = styled(Centered)`
  text-decoration: line-through;
  margin-bottom: 4px;
  color: grey;
`;
const PricePromo = styled(Centered)`
  font-weight: 500;
  /* font-size: 15px; */
`;
const Warning = styled(CenteredByColumn)`
  ${card}
  z-index: 1;
  width: 50vw;
  height: 50vh;
  ${textTitle}
`;
export interface AddButtonProps {
  disabled: boolean;
}
const AddButton = styled(ActionBlack)<AddButtonProps>`
  /* ${actionBlack} */
  position: absolute;
  right: 15px;
  bottom: 10px;
  padding: 0px 10px;
  height: 30px;
  font-size: 11px;
  background-color: ${(props) => (props.disabled ? "grey" : "black")};
`;
const RadioGroup = ({ options, selectedOptionIndex, onChange }) => {
  return (
    <RadioGroupContainer>
      {options.map((option, index) => (
        <label key={index}>
          <input
            type="radio"
            name="radioGroup"
            value={option.value}
            checked={selectedOptionIndex === index}
            onChange={() => onChange(index)}
          />
          {option.label}
        </label>
      ))}
    </RadioGroupContainer>
  );
};

const ButtonClose = styled.button`
  padding: 0px;
  margin: 0px;
  position: fixed;
  top: ${NavHeight + 30}px;
  left: 20px;
  width: 50px;
  height: 50px;
  ${centered}
  font-size: 24px;
`;
const AllContainer = styled(Centered)`
  width: 100vw;
  height: calc(100vh - ${topHeight * 2}px);
  position: fixed;
  top: ${topHeight}px;
  left: 0px;
  overflow: scroll;
  background-color: white;
  z-index: 1;
  overflow-y: scroll;
  /* justify-content: flex-start; */
  align-items: flex-start;
  padding: ${topHeight + 20}px 0px;
  flex-wrap: wrap;
  button {
    flex-grow: 0;
    max-height: 200px;
    z-index: 5;
  }
`;
const ALLTitle = styled(Centered)`
  width: 100vw;
  height: ${topHeight}px;
  line-height: ${topHeight}px;
  text-align: center;
  position: fixed;
  top: ${topHeight}px;
  left: 0px;
  ${card}
  margin:0px;
  margin-bottom: 20px;
  z-index: 100;
  span {
    ${textTitle}
  }
`;
const AllDrinks = ({ dishes, onSelectDish, disableAddButton, onClose }) => (
  <AllContainer>
    <ALLTitle>
      <ButtonClose onClick={onClose}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </ButtonClose>
      <span>Step 3. Select Your Treat</span>
    </ALLTitle>

    {dishes.map((dish, dishIndex) => (
      <Dish
        onClick={onSelectDish.bind(this, dishes, dishIndex)}
        disabled={disableAddButton}
      >
        <Image backgroundImageUrl={dish.imageURL} />
        <Name>{dish.name}</Name>
        <PriceContainer>
          <PriceRegular>${dish.priceDoordashListed.toFixed(2)}</PriceRegular>
          <PricePromo>${dish.priceFoodieListed.toFixed(2)}</PricePromo>
        </PriceContainer>
        <AddButton disabled={disableAddButton}>add</AddButton>
      </Dish>
    ))}
  </AllContainer>
);
const DeliveryRange = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: flex-start;   */
  span {
    ${text12300}
    display: inline;
    white-space: nowrap;
    margin-right: 5px;
    &:after {
      content: ",";
    }
  }
  span:last-child {
    &:after {
      content: ".";
    }
  }
`;
const DeliveryZips = styled(CenteredByColumn)`
  ${card}
  z-index: 2;
  ${text12300}
  padding: 20px;
  position: fixed;
  width: 300px;
  left: calc(50vw - 150px);
  top: 100px;
  h1 {
    ${textTitle}
    font-size: 15px;
    text-align: center;
  }
  ul {
    width: 100%;
    ${CenteredByColumn}
    /* background-color: red; */
    background-color: white;
    li {
      align-self: stretch;
      text-align: center;
      svg {
        margin-right: 10px;
      }
    }
  }
  button {
    ${actionBlack}
    margin-top: 40px;
  }
`;
const InfoIcon = styled.button`
  margin: 0px;
  padding: 0px;
  ${textTitle}
  text-transform: lowercase;
  font-size: 13px;
  font-style: italic;
  line-height: 10px;
  flex-grow: 0;
  flex-shrink: 0;
  /* position: absolute; */
  /* right: 0px; */
  position: relative;
  top: 1px;
  margin: 0px 2px 0px 3px;
  z-index: 0;
`;
const BackButton = styled.button`
  width: 60px;
  height: ${topHeight}px;
  position: fixed;
  top: 0px;
  left: 0px;
  ${centered}
  margin: 0px;
  padding: 0px;
  svg {
    font-size: 20px;
  }
  @media only screen and (min-width: 800px) {
    margin-left: 8%;
  }
`;
export interface ReferralAwardProps {
  socialInviteCode: string;
  dishes: any[];
  promoMeals: any[];
  restaurant: string;
}
export const ReferralAward: React.FC<ReferralAwardProps> = ({
  promo,
  socialInviteCode,
  dishes,
  promoMeals,
  restaurant,
}: ReferralAwardProps): ReactElement => {
  const cart = useSelector((state: RootState) => state.cart.payLoad);
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const store = useStore();

  const [selectedDrinkIndex, setSelectedDrinkIndex] = useState(-1);
  const [selectedDessertIndex, setSelectedDessertIndex] = useState(-1);
  const [selectedDishCount, setSelectedDishCount] = useState(-1);
  const [showZip, setShowZip] = useState(false);
  const [showAllDrinks, setShowAllDrinks] = useState(false);
  const [showAllDesserts, setShowAllDesserts] = useState(false);
  const [windowIndex, setWindowIndex] = useState(0);
  const [timer, setTimer] = useState(null);
  const [addingDish, setAddingDish] = useState(null);
  const [itemsChanged, setItemsChanged] = useState(false);

  LOGGING &&
    console.log("ReferralAward rendeirng:", {
      showAllDrinks,
      showAllDesserts,
      cart,
      location,
    });

  const fetch = useCallback(async () => {
    return await GetReferrerFromCode(socialInviteCode);
  }, []);

  const { data } = useAsync({ promiseFn: fetch });
  const referrer = data
    ? `${data.firstName} ${data.lastName}`
    : socialInviteCode[0] +
      socialInviteCode.slice(1, socialInviteCode.length - 3).toLowerCase();

  const windows = promoMeals.map((meal) =>
    moment(meal.windowStart).weekday() === 1
      ? {
          value: meal.windowStart,
          label: `
        ${moment(meal.windowStart).add(-180, "m").format("HH:mmA")}
        -
        ${moment(meal.windowStart)
          .add(-120, "minute")
          .format("HH:mmA")},        
        ${moment(meal.windowStart).format("ddd, MMM DD, YYYY")}`,
        }
      : {
          value: meal.windowStart,
          label: `
        ${moment(meal.windowStart).format("HH:mmA")}
        -
        ${moment(meal.windowStart).add(90, "minute").format("HH:mmA")},        
        ${moment(meal.windowStart).format("ddd, MMM DD, YYYY")}`,
        }
  );

  // handler for selecting a dish
  const onSelectDish = (dishes, dishIndex, e) => {
    LOGGING && console.log("onSelectDish called with:", { dishIndex, e });
    e.preventDefault();
    e.stopPropagation();

    const dish = dishes[dishIndex];
    LOGGING && console.log("onSelectDish called with dish:", { dish });

    const countInCart = cart?.goods
      ? Object.keys(cart.goods).reduce(
          (a, b) =>
            (String(b).includes(String(dish._id))
              ? cart.goods[b].quantity
              : 0) + a,
          0
        )
      : 0;

    if (dish.dataSource === "CRAWLING_PLATFORM_COPY") {
      setSelectedDrinkIndex(dishIndex);
      setSelectedDessertIndex(-1);
    } else {
      setSelectedDrinkIndex(-1);
      setSelectedDessertIndex(dishIndex);
    }
    setSelectedDishCount(countInCart);
  };

  // handler for selecting a delivery window
  const onSelectWindow = (windowIndex) => {
    LOGGING && console.log("onSelectWindow called with:", { windowIndex });
    setWindowIndex(windowIndex);
  };

  // handler for closing a selection
  const handleCloseSelections = () => {
    setSelectedDrinkIndex(-1);
    setSelectedDessertIndex(-1);
    setSelectedDishCount(-1);
  };

  // handler for adding an item to cart
  const handleAddGoodsToCart = (goods) => {
    LOGGING && console.log("handleAddGoodsToCart called with goods: ", goods);
    LOGGING &&
      console.log(
        "handleAddGoodsToCart called with meal: ",
        promoMeals[windowIndex]
      );

    addGoodsToCart({
      goodsToAdd: goods,
      window: {
        start: promoMeals[windowIndex].windowStart,
        end: promoMeals[windowIndex].windowStart + 60 * 60 * 1000,
        cost: 0,
      },
      referralCode: socialInviteCode,
      mealId: promoMeals[windowIndex]._id,
      isMember: true,
      promo: promo,
    })(dispatch, store.getState);

    setTimer(
      setTimeout(() => {
        LOGGING && console.log("settime out is up");
        setAddingDish(goods[0]?.dish?._id);
        updateOrderOnMeal()(dispatch, store.getState)
          .then(() => {
            LOGGING && console.log("updateOrderOnMeal done on PageSingleMeal.");
            setAddingDish(null);
            setItemsChanged(true);
          })
          .catch((e) => {
            LOGGING &&
              console.log("updateOrderOnMeal failed on PageSingleMeal.", e);
            toast.error(e.message);
          });
      }, 500)
    );

    if (!currentUser.isAuthenticated) {
      history.push({
        pathname: `/referrals/${socialInviteCode}`,
        state: {
          title: "Sign Up To Continue",
          next: location.pathname,
          secondary: { title: "Create an Account", next: "/signup" },
          fromMeal: true,
        },
      });
    }
  };

  const countInCart = cart?.goods
    ? Object.keys(cart.goods).reduce((a, b) => cart.goods[b].quantity + a, 0)
    : 0;

  const disableAddButton = countInCart >= promo.amount;
  const quantityCap = promo.amount - countInCart;

  LOGGING &&
    console.log("ReferralAward rendeirng with:", {
      referrer,
      countInCart,
      disableAddButton,
      windowIndex,
      currentUser,
      socialInviteCode,
      dishes,
    });

  const drinks = dishes.filter(
    (dish) => dish.dataSource === "CRAWLING_PLATFORM_COPY"
  );
  const desserts = dishes.filter((dish) => dish.dataSource === "MANUAL");

  return (
    <ContentContainer>
      <Toaster />
      {/*@ts-ignore*/}
      {/* <PopUp
        isPoppedUp={
          currentUser.isAuthenticated &&
          currentUser.user.socialInviteCode.code !== socialInviteCode &&
          //@ts-ignore
          !location.state?.fromCart
        }
        componentToDisplay={<Warning>new user only</Warning>}
      /> */}
      <Title>
        <BackButton onClick={() => history.push("/")}>
          <FontAwesomeIcon icon={faHouse} />
        </BackButton>
        <TitleText>
          <span>Fall Treats On Us!</span>
          <label>
            {currentUser?.isAuthenticated
              ? `for member ${referrer} & friends`
              : `for ${referrer.split(" ")[0]}'s friends`}
          </label>
          <b>(new users only)</b>
        </TitleText>
        <Icon>🍁</Icon>
      </Title>
      {selectedDrinkIndex >= 0 ? (
        //@ts-ignore
        <DishChooserContainer
          dish={drinks[selectedDrinkIndex]}
          isOpen={true}
          modalify={true}
          dishId={drinks[selectedDrinkIndex]._id}
          onClose={handleCloseSelections}
          onAddGoodsToCart={handleAddGoodsToCart}
          countInCart={selectedDishCount}
          isMember={true}
          isAuthenticated={true}
          quantityCap={quantityCap}
        />
      ) : null}
      {selectedDessertIndex >= 0 ? (
        //@ts-ignore
        <DishChooserContainer
          dish={desserts[selectedDessertIndex]}
          isOpen={true}
          modalify={true}
          dishId={desserts[selectedDessertIndex]._id}
          onClose={handleCloseSelections}
          onAddGoodsToCart={handleAddGoodsToCart}
          countInCart={selectedDishCount}
          isMember={true}
          isAuthenticated={true}
          quantityCap={quantityCap}
        />
      ) : null}
      <Section>
        <SectionTitle>1. Check Delivery Range</SectionTitle>
        <SectionSubTitle>new friends, are you in these cities?</SectionSubTitle>
        <DeliveryRange>
          <span>Mountain View</span>
          <span>Los Altos</span>
          <span>Stanford</span>
          <span>Palo Alto</span>
          <span>East Palo Alto</span>
          <span>Menlo Park</span>
          <span>Sunnyvale</span>
          <span>Cupertino</span>
          <span>Santa Clara</span>
          <span>Saratoga</span>
          <span>Campbell</span>
          <span>Milpitas</span>
          <span>Monte Sereno</span>
          <span>Atherton</span>
          <span>
            San Jose (select zip codes
            <InfoIcon
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowZip(true);
              }}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </InfoIcon>
            )
          </span>
        </DeliveryRange>
      </Section>
      {/* @ts-ignore */}
      <PopUp
        isPoppedUp={showZip}
        componentToDisplay={
          <DeliveryZips>
            <h1>Supported San Jose Zip Codes</h1>
            <ul>
              {SupportedSanJoseZipCodes.map((zip, index) => (
                <li key={index}>
                  <FontAwesomeIcon icon={faCheck} />
                  {zip}
                </li>
              ))}
            </ul>
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowZip(false);
              }}
            >
              Got it!
            </button>
          </DeliveryZips>
        }
        hidePopUp={() => {
          setShowZip(false);
        }}
      />
      <Section>
        {/* select delivery time */}
        <SectionTitle>2. Select Delivery Time</SectionTitle>
        <SectionSubTitle>
          <FontAwesomeIcon icon={faTruck} />
          Delivery fee <b>$0.00</b>
        </SectionSubTitle>
        <SectionContent>
          {/* <SectionSubTitle><label>Delivery Fee:</label><b>$0.00</b></SectionSubTitle> */}
          <RadioGroup
            options={windows}
            selectedOptionIndex={windowIndex}
            onChange={onSelectWindow}
          />
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>3. Select Drink</SectionTitle>
        <SectionSubTitle>
          <FontAwesomeIcon icon={faHouse} />
          {restaurant}
        </SectionSubTitle>
        <CarouselMobile>
          {drinks.map((dish, dishIndex) => (
            <Dish
              onClick={onSelectDish.bind(this, drinks, dishIndex)}
              disabled={disableAddButton}
            >
              {/* <DishOverlay></DishOverlay> */}
              <DishIndex>
                <span>
                  {dishIndex + 1} of {drinks.length}
                </span>
              </DishIndex>
              <Image backgroundImageUrl={dish.imageURL} />
              <Name>{dish.name}</Name>
              <PriceContainer>
                <PriceRegular>
                  ${dish.priceDoordashListed.toFixed(2)}
                </PriceRegular>
                <PricePromo>${dish.priceFoodieListed.toFixed(2)}</PricePromo>
              </PriceContainer>
              <AddButton disabled={disableAddButton}>add</AddButton>
            </Dish>
          ))}
        </CarouselMobile>
        <CarouselDesktop>
          {drinks.slice(0, 3).map((dish, dishIndex) => (
            <Dish
              onClick={onSelectDish.bind(this, drinks, dishIndex)}
              disabled={disableAddButton}
              key={dishIndex}
            >
              <Image backgroundImageUrl={dish.imageURL} />
              <Name>{dish.name}</Name>
              <PriceContainer>
                <PriceRegular>
                  ${dish.priceDoordashListed.toFixed(2)}
                </PriceRegular>
                <PricePromo>${dish.priceFoodieListed.toFixed(2)}</PricePromo>
              </PriceContainer>
              <AddButton disabled={disableAddButton}>add</AddButton>
            </Dish>
          ))}
          <SeeMore
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowAllDrinks(true);
            }}
          >
            <label>{`${drinks.length} drinks`}</label>
            <span>View All</span>
          </SeeMore>
        </CarouselDesktop>
        {/* <SectionSubTitle>
          <FontAwesomeIcon icon={faHouse} />
          <label>Sweet Streat Disserts</label>
        </SectionSubTitle>
        <CarouselMobile>
          {desserts.map((dish, dishIndex) => (
            <Dish
              onClick={onSelectDish.bind(this, desserts, dishIndex)}
              disabled={disableAddButton}
            >
              <Image backgroundImageUrl={dish.imageURL} />
              <Name>{dish.name}</Name>
              <PriceContainer>
                <PriceRegular>
                  ${dish.priceDoordashListed.toFixed(2)}
                </PriceRegular>
                <PricePromo>${dish.priceFoodieListed.toFixed(2)}</PricePromo>
              </PriceContainer>
              <AddButton disabled={disableAddButton}>add</AddButton>
            </Dish>
          ))}
        </CarouselMobile>
        <CarouselDesktop>
          {desserts.slice(0, 3).map((dish, dishIndex) => (
            <Dish
              onClick={onSelectDish.bind(this, desserts, dishIndex)}
              disabled={disableAddButton}
              key={dishIndex}
            >
              <Image backgroundImageUrl={dish.imageURL} />
              <Name>{dish.name}</Name>
              <PriceContainer>
                <PriceRegular>
                  ${dish.priceDoordashListed.toFixed(2)}
                </PriceRegular>
                <PricePromo>${dish.priceFoodieListed.toFixed(2)}</PricePromo>
              </PriceContainer>
              <AddButton disabled={disableAddButton}>add</AddButton>
            </Dish>
          ))}
          <SeeMore
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowAllDesserts(true);
            }}
          >
            <label>{`${desserts.length} desserts`}</label>
            <span>View All</span>
          </SeeMore>
        </CarouselDesktop> */}
        {/*@ts-ignore */}
        {showAllDrinks ? (
          <AllDrinks
            dishes={drinks}
            onSelectDish={onSelectDish}
            disableAddButton={disableAddButton}
            onClose={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowAllDrinks(false);
            }}
          />
        ) : null}
        {showAllDesserts ? (
          <AllDrinks
            dishes={desserts}
            onSelectDish={onSelectDish}
            disableAddButton={disableAddButton}
            onClose={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowAllDesserts(false);
            }}
          />
        ) : null}
      </Section>
      {/* <Footer> */}
      {/*@ts-ignore*/}
      <CheckoutBanner
        cart={cart}
        userId={currentUser?.user?._id}
        onNext={() => {
          history.push({
            pathname: "/cart",
            state: {
              newUserOnly: true,
              referrer,
              treatPath: location?.pathname,
            },
          });
        }}
      />
      {/* </Footer> */}
    </ContentContainer>
  );
};
const EntryContainer = styled(Link)`
  ${centered}
  ${text12300}  
  /* background-color: #4646c2; */
  /* background-color: #85E21F; */
  /* background-color: black; */

  width: 100vw;
  height: ${NavHeight + 20}px;
  background-color: ${FallOrange};
  /* background-color: #e8e02b */
  /* background-color: orange; */
  /* background-color: ${Pink}; */
  /* background-color: #4646c2; */
  /* background-color: #9B1003; */
  /* background-color: rgb(70,70,194) ; */
  /* color: white; */
  /* background-color: #1e90ff; */
  /* background-color: ${WinterBlue}; */

  svg {
    font-size: 20px;
    position: absolute;
    right: 15px;
  }
  label {
    /* font-size: 30px; */
    margin: 0px 10px;
  }
  /* label:nth-child(3) {
      margin-left: 0px;
    } */
  img {
    height: ${NavHeight * 1.4}px;
    transform: rotate(15deg);
    position: relative;
    left: -5px;
    top: -1px;
  }
  label {
    font-size: 40px;
  }
  @media only screen and (max-width: 350px) {
    svg {
      font-size: 20px;
      position: absolute;
      right: 20px;
    }
    padding-right: 40px;
    label:nth-child(3) {
      display: none;
    }
  }
  @media only screen and (min-width: 500px) {
    /* width: 500px; */
    svg {
      left: calc(50% + 200px);
      right: auto;
    }
  }
`;
const shakeAnimation = keyframes`
  0% {
    transform: translate(-1px, -1px) rotate(-10deg);
    transform-origin: 50% 50%;
  }
  25% {
    transform: translate(1px, 1px) rotate(5deg);
    transform-origin: 50% 50%;
  }
  40% {
    transform: translate(-1px, -1px) rotate(-10deg);
    transform-origin: 50% 50%;
  }
  85% {
    transform: translate(1px, 1px) rotate(5deg);
    transform-origin: 50% 50%;
  }
  100% {
    transform: translate(0, 0) rotate(0);
    transform-origin: 100% 100%;
  }
`;
const Text = styled(CenteredByColumn)`
  /* padding-right: 40px; */
  span:first-child {
    ${textTitle}
    font-size: 12px;
    /* font-weight: 500; */
    line-height: 1;
    margin-bottom: 5px;
    z-index: 1;
  }
  span:nth-child(2) {
    /* color: #85E21F; */
    /* color: orange; */
    /* color: black;     */
    /* ${textTitle} */
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    /* text-transform: normal; */
    text-transform: uppercase;
    z-index: 1;
  }
  span:last-child {
    ${textTitle}
    font-size: 10px;
    /* font-weight: 500; */
    line-height: 1;
    margin-top: 5px;
    z-index: 1;
  }
`;
const ShakingLabel = styled.label`
  font-size: 50px;
  margin-left: 5px;
  /* animation: ${shakeAnimation} 1s ease-in-out infinite;  */
  /* Adjust duration and timing function as needed */
  display: inline-block;
  @media only screen and (min-width: 500px) {
    display: none;
  }
`;
export const WinterReferralAwardEntry = () => (
  <EntryContainer to={"/referral-event"}>
    <label>❄️</label>
    {/* <label>🏂</label> */}
    <Text>
      <span>Member Only</span>
      <span>winter Treats on us!</span>
      <span>Ends 1.11.2024</span>
    </Text>
    {/* <img src={IconImages.milktea.portrait} /> */}
    {/* <ShakingLabel>🧋</ShakingLabel> */}
    <FontAwesomeIcon icon={faChevronRight} />
  </EntryContainer>
);
export const SpringReferralAwardEntry = () => (
  <EntryContainer to={"/referral-event"}>
    <label>🌸</label>
    <Text>
      <span>Member Only</span>
      <span>Spring Treats on us!</span>
      <span>Claim by 3:30PM Thursday 3.7.2024</span>
    </Text>
    {/* <img src={IconImages.milktea.portrait} /> */}
    {/* <ShakingLabel>🧋</ShakingLabel> */}
    <FontAwesomeIcon icon={faChevronRight} />
  </EntryContainer>
);
export const SummerReferralAwardEntry = () => (
  <EntryContainer to={"/referral-event"}>
    <label>🍁</label>
    <Text>
      <span>Member Only</span>
      <span>Fall Treats on us!</span>
      <span>Claim by Monday 9.16.2024</span>
    </Text>
    <label>🧋</label>
    {/* <img src={IconImages.milktea.portrait} /> */}
    {/* <ShakingLabel>🧋</ShakingLabel> */}
    <FontAwesomeIcon icon={faChevronRight} />
  </EntryContainer>
);

export const RegularReferralEntry = ({}) => (
  <EntryContainer to="/referrals">
    {/* <label>💰</label> */}
    {/* <label>💵</label> */}
    {/*🍣*/}
    <label>🍔</label>
    <Text>
      <span>Refer A Friend,</span>
      <span>Get Free food</span>
      <span></span>
    </Text>
    {/* <img src={IconImages.milktea.portrait} /> */}
    {/* <ShakingLabel>🧋</ShakingLabel> */}
    <FontAwesomeIcon icon={faChevronRight} />
  </EntryContainer>
);
