import React from "react";
import { faCrown as faMember } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthMode } from "src/constants";
import styled from "styled-components";
import { SectionHeader } from "./Testimonials";
import meal_for_3 from "../../../../meal3.png";
import { priceTable } from "../customers/banners/CompetitorComparison";
import {
  CenteredByColumn,
  text12300,
  card,
  Gold,
  Centered,
  textTitle,
  YelpRed,
  Beige,
  BorderGrey,
  TextGrey,
  text14300,
  CenteredByColumnLeft,
  NavHeight,
  Card,
  actionBlack,
  actionUnderline,
  actionGrey,
  centered,
  Peach,
  ALLCAPTEXT,
  Lavendar,
  Pink,
  PinkPeach,
  centeredByColumn,
  Lotus,
  BreakPoint,
} from "../Shared";
export const breakPoint = BreakPoint;
type BenefitProps = {
  mode?: number;
  // ref?: any;
};
const Benefit = styled(CenteredByColumn)<BenefitProps>`
  /* margin-bottom: 20px; */
  align-self: stretch;
  flex-grow: 1;
  padding: 15px;
  ${card}
  background-color: ${PinkPeach};
  /* top: -40px; */
  /* background-color: white; */
  /* box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.16) 0px 0px 5px 0px; */
  z-index: 5;
  /* position: relative; */
  /* top: ${(props) => (props.mode === AuthMode.BOTH ? "-25px" : "-50px")}; */
  > h1 {
    ${ALLCAPTEXT}
  }
  > div:last-child {
    margin-bottom: 0px;
    &:after {
      display: none;
    }
  }
  /* &:nth-child(odd) {
    background-color: ${Beige};
  } */
  /* &:nth-child(even){
    background-color: white;
  } */
  @media only screen and (min-width: ${breakPoint}px) {
    right: 0px;
    left: auto;
    /* left: 500px; */
    position: absolute;
    /* top: calc(${NavHeight}px); */
    /* top: 3vw; */
    top: 0px;
    padding-top: 40px;
    width: 25vw;
    min-width: 420px;
    margin-top: 0px;
    background-color: #ffe3d4;

    align-self: auto;
    flex-grow: 0;
    min-height: calc(100% + 20px);
  }
`;

const BenefitBorder = styled(CenteredByColumn)`
  position: relative;
  border: 2px solid rgb(218, 89, 89) !important;
  padding: 0px 20px;
  /* top: 0px; */
  flex-grow: 1;
  align-self: stretch;
  /* a, */
  h1 {
    ${text12300}
    font-size: 24px;
    line-height: 1.2;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0px 0px 40px 0px;
    white-space: pre-wrap;
    text-align: center;
    width: calc(100% - 40px);
    /* display: none; */
  }
  button {
    /* ${actionGrey};
    text-transform: uppercase; */
    margin-bottom: 40px;
    /* padding: 15px 20px;
    font-weight: 500;
    letter-spacing: 1px; */
    /* border-color: ${BorderGrey}; */
  }
  @media only screen and (min-width: ${breakPoint}px) {
    padding: 40px 20px 20px 20px;
    border: none;
    div:last-child {
      border: none;
    }
    /* a, */
    button {
      margin: 20px 0px;
      background-color: transparent;
      border: 1px solid black;
    }
    h1 {
      margin-bottom: 30px;
      width: 100%;
      text-align: center;
      display: block;
    }
  }
`;

type SizeProps = {
  MobileOnly?: boolean;
  DesktopOnly?: boolean;
};
const Section = styled(CenteredByColumn)`
  margin-bottom: 10px;
  /* background-color: white; */
  align-self: stretch;
  align-content: stretch;
  flex-grow: 1;
  position: relative;
  @media only screen and (min-width: ${breakPoint}px) {
    border-bottom: 1px solid rgb(219, 217, 217);
    flex-grow: 0;
    align-self: auto;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0; /* Set margin-bottom to 0 for the last child */
    }
  }
`;
const BenefitDesktop = styled(CenteredByColumn)<BenefitProps>`
  position: absolute;
  ${card}
  /* top: ${NavHeight}px; */
  top: calc(-75vh + ${NavHeight}px);
  right: 40px;
  background-color: white;
  z-index: 6;
  padding: 15px;
  border: 1px solid ${BorderGrey};
  width: 300px;
  @media only screen and (min-width: ${breakPoint}px) {
    right: 8vw;
  }
`;
const SectionIcon = styled(Centered)<SizeProps>`
  font-size: 40px;
  padding: 0px;
  flex-shrink: 0;
  align-self: stretch;
  display: ${(props) => (props.DesktopOnly ? "none" : "flex")};
  @media only screen and (min-width: ${breakPoint}px) {
    display: ${(props) => (props.MobileOnly ? "none" : "flex")};
  }
`;
const SectionContent = styled(CenteredByColumn)`
  padding: 10px 0px 20px 0px;
  width: 100%;
  /* border: 1px solid red; */
  > button {
    /* display: inline-block; */
    background-color: transparent;
    ${text12300}
    padding: 0px;
    margin: 0px;
    font-size: 13px;
    line-height: 1.8;
    border-bottom: 1px solid black;
    text-transform: none;
    @media only screen and (min-width: ${breakPoint}px) {
      background-color: transparent;
      border: none;
      line-height: 1.6;
      border-bottom: 1px solid black;
      margin: 10px 0px;
    }
  }
`;
const SectionCount = styled(Centered)`
  ${textTitle}
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 5px;
`;

const SectionWords = styled.span<SizeProps>`
  ${text12300}
  text-align: center;
  font-size: 13px;
  line-height: 1.8;
  display: ${(props) => (props.DesktopOnly ? "none" : "block")};
  width: 100%;

  @media only screen and (min-width: ${breakPoint}px) {
    display: ${(props) => (props.MobileOnly ? "none" : "block")};
  }
`;
const Step = styled(Section)`
  @media only screen and (min-width: ${breakPoint}px) {
    flex-direction: column;
    width: 33%;
    /* ${card} */
    margin: 10px 20px;
    /* border: 1px solid ${BorderGrey}; */
    padding: 30px 0px;
    background-color: white;
    border: none;
    /* background-color: #ffe3d4; */
    flex-grow: 0;
  }
`;

const StepContent = styled(SectionContent)`
  @media only screen and (min-width: ${breakPoint}px) {
    padding: 10px 0px 20px 0px;
  }
`;
const StepCount = styled(SectionCount)`
  @media only screen and (min-width: ${breakPoint}px) {
    margin-bottom: 10px;
  }
`;
const StepWords = styled(SectionWords)<SizeProps>`
  @media only screen and (min-width: ${breakPoint}px) {
    display: ${(props) => (props.MobileOnly ? "none" : "block")};
  }
`;
export const Container = styled.div`
  ${centeredByColumn}
  align-self: stretch;
  background-color: ${Lotus};
  z-index: 5;
  padding: 0 0 40px 0;
  @media only screen and (min-width: ${breakPoint}px) {
    padding: 30px 8vw 70px 8vw;
    background-color: ${Beige};
    h1 {
      margin: 40px 0px 20px 0px;
    }
  }
`;
export const Benefits = React.forwardRef((props, ref) => (
  <Container>
    {/* <BenefitBorder> */}
    <SectionHeader ref={ref}>Benefits</SectionHeader>
    {/* <h1>
        Doordash too expensive?<br></br>Try Foodie!
      </h1> */}

    <Section>
      <SectionIcon>🛣️</SectionIcon>
      <SectionContent>
        <SectionCount>4x Farther</SectionCount>
        <SectionWords>than Doordash/Uber Eats.</SectionWords>
        {/* <Link to="/restaurants">See Restaurants</Link> */}
      </SectionContent>
    </Section>
    <Section>
      <SectionIcon>💰</SectionIcon>
      {/* <OverallComparison/> */}

      <SectionContent>
        <SectionCount>No Mark-Up</SectionCount>
        {/* <SectionLink to="/pricecomparison">
            15~40% cheaper
            <FontAwesomeIcon icon={faInfoCircle} />
          </SectionLink> */}
        <SectionWords>
          i.e. lower food price than Doordash/Uber Eats.
        </SectionWords>
        {/* <SectionWords>even with Dashpass/Uber One.</SectionWords> */}
        {/* <button
          onClick={(e) => {
            e.preventDefault();
            console.log("button clicked!");
            onShowComparePrice();
          }}
        >
          Example
        </button> */}
      </SectionContent>
    </Section>
    <Section>
      <SectionIcon>🌎</SectionIcon>
      <SectionContent>
        <SectionCount>70% less pollution</SectionCount>
        <SectionWords>than Doordash/Uber Eats.</SectionWords>
      </SectionContent>
    </Section>
    {/* @ts-ignore */}
    {props?.auth}
    {/* </BenefitBorder> */}
  </Container>
));

export const BenefitsDesktop = React.forwardRef((props, ref) => (
  <BenefitDesktop>
    {/* <SectionHeader ref={ref}>Benefits</SectionHeader> */}
    <BenefitBorder>
      <h1>
        Doordash too boring?<br></br>Try Foodie!
      </h1>

      <Section>
        <SectionIcon>🛣️</SectionIcon>
        <SectionContent>
          <SectionCount>4x Farther</SectionCount>
          <SectionWords>than Doordash/Uber Eats.</SectionWords>
          {/* <Link to="/restaurants">See Restaurants</Link> */}
        </SectionContent>
      </Section>
      <Section>
        <SectionIcon>💰</SectionIcon>
        {/* <OverallComparison/> */}

        <SectionContent>
          <SectionCount>No Mark-Up</SectionCount>
          {/* <SectionLink to="/pricecomparison">
            15~40% cheaper
            <FontAwesomeIcon icon={faInfoCircle} />
          </SectionLink> */}
          <SectionWords>
            i.e. lower food price than Doordash/Uber Eats.
          </SectionWords>
          {/* <SectionWords>even with Dashpass/Uber One.</SectionWords> */}
          {/* <button
            onClick={(e) => {
              e.preventDefault();
              console.log("button clicked!");
              // onShowComparePrice();
            }}
          >
            Example
          </button> */}
        </SectionContent>
      </Section>
      <Section>
        <SectionIcon>🌎</SectionIcon>
        <SectionContent>
          <SectionCount>70% less pollution</SectionCount>
          <SectionWords>than Doordash/Uber Eats.</SectionWords>
        </SectionContent>
      </Section>
    </BenefitBorder>
  </BenefitDesktop>
));

const Image = styled.img`
  width: 50%;
  align-self: stretch;
  flex-grow: 1;
  /* margin-right: 10px; */
  /* border: 1px solid green; */
`;
export const AnonymousCompetitorComparison = () => {
  return (
    <Benefit>
      <BenefitBorder>
        <Step>
          <Image src={meal_for_3}></Image>
          <StepContent>
            <StepCount>Dine-in</StepCount>
            <StepWords>
              <label>$</label>
              {52}
            </StepWords>
          </StepContent>
        </Step>

        <StepCount>delivery (5 miles)</StepCount>
        {Object.keys(priceTable).map((priceName, index) => (
          <Step>
            <StepCount>
              {priceName}
              <FontAwesomeIcon icon={faMember} />
            </StepCount>
            <StepCount>
              <label>$</label>
              {priceTable[priceName]}
            </StepCount>
          </Step>
        ))}
      </BenefitBorder>
    </Benefit>
  );
};
